import React, { useEffect } from "react";
import { Flex, Loader, Text } from '@mantine/core';


export const DietDisplayer = () => {
    const [hasLoaded, setHasLoaded] = React.useState(false);

    useEffect(() => {
        setTimeout(() => {
            setHasLoaded(true);
        }, 500);
    }, []);

    return (
        <>
            <Flex
                mih={ 50 }
                gap="md"
                justify="center"
                align="center"
                direction="column"
                wrap="wrap"
            >
                {hasLoaded ? (
                    <Text>Muchas gracias por utilizar nuestro servicio. Recibirás un email con tu menú semanal en breve.</Text>
                )
                :
                <Loader/>
                }

            </Flex>
        </>
    );

};
