import React from "react";
import {Button, Input} from '@mantine/core';
import {IconAt, IconChevronRight} from '@tabler/icons';

export interface IEmailCatcherProps {
    nextStep: () => void;
    setStateEmail: (data: {email: string, shouldSubscribe: boolean} | null) => void;
}

export const EmailCatcher = ({nextStep, setStateEmail}: IEmailCatcherProps) => {
    const [form, setForm] = React.useState({
        email: '',
        checked: true,
    });

    const [errors, setErrors] = React.useState({
        email: '',
    });

    const submit = () => {
        if (form.email === '') {
            setErrors({...errors, email: 'El email es requerido'});
            return;
        }
        // Validate email
        if (!form.email.includes('@') || !form.email.includes('.') || form.email.length < 5) {
            setErrors({...errors, email: 'El email no es valido'});
            return;
        }

        // Set the email in the parent component
        setStateEmail({ email: form.email, shouldSubscribe: true});
        nextStep();
    };

    return (
        <>
            <Input.Wrapper
                id="input-demo"
                withAsterisk
                label="Email"
                description="Para poder usar el servicio, es necesario que nos proporciones tu email"
                error={errors.email}
            >
                <Input
                    icon={<IconAt/>}
                    placeholder="Email"
                    value={form.email}
                    onChange={(event) => setForm({...form, email: event.currentTarget.value})}
                />
            </Input.Wrapper>
            <Button rightIcon={<IconChevronRight/>} onClick={submit}>Siguiente</Button>
        </>
    );

};
