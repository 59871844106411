import React from "react";
import { Container, Flex, Paper, Text, Title } from '@mantine/core';
import { EmailCatcher } from '../components/EmailCatcher';
import { DietGenerator } from '../components/DietGenerator';
import { DietDisplayer } from '../components/DietDisplayer';
import { SessionService } from '../services/Session/SessionService';
import { generateRecipe, RecipeRestriction } from '../services/Firebase/firebase.service';
import logo from '../assets/logo.png';
import miMenuLogo from '../assets/MiMenu.png';

const Login = () => {
    const [stepNumber, setStepNumber] = React.useState(0);
    const [stateEmail, setStateEmail] = React.useState<{ email: string, shouldSubscribe: boolean } | null>(null);

    async function generateDiet(diet: RecipeRestriction[]) {
        const sessionId = await new SessionService().generateSession();

        await generateRecipe(diet, sessionId, stateEmail?.email ?? '', stateEmail?.shouldSubscribe ?? false);
    }

    return (
        <>
            <Container fluid style={ { backgroundColor: '#00593b' } }>
                <Container py="md">
                    <Flex
                        mih={ 50 }
                        gap="md"
                        justify="center"
                        align="center"
                        direction="column"
                        wrap="wrap"
                    >
                        <Paper shadow="xs" radius="md" p="md" style={ { width: '100%' } }>
                            <div style={ {
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            } }>
                                <img src={ miMenuLogo } alt="Logo" style={ { width: '280px' } }/>
                            </div>
                            { stepNumber !== 2 && (
                                <>
                                    <Flex
                                        gap="md"
                                        justify="center"
                                        align="center"
                                        direction="column"
                                        wrap="nowrap"
                                    >
                                        <Title order={ 2 } align="center">Generador de menú semanal</Title>
                                        <Text align="center">MI MENÚ te ayuda a planificar tu comida de la semana y te ofrece múltiples ideas de recetas.</Text>
                                        <div>
                                            <Text align="center">Introduce tu email, tus preferencias o restricciones alimenticias</Text>
                                            <Text align="center">y recibe automáticamente un menú semanal personalizado en tu correo electrónico.</Text>
                                        </div>
                                        <Text align="center" sx={ { marginBottom: "12px" } }>¿A qué esperas? ¡Pruébalo ahora!</Text>
                                    </Flex>
                                </>
                            ) }

                            <Flex
                                gap="md"
                                justify="center"
                                align="center"
                                direction="row"
                                wrap="wrap"
                            >
                                { stepNumber === 0 && (<EmailCatcher nextStep={ () => setStepNumber(stepNumber + 1) }
                                                                     setStateEmail={ setStateEmail }/>) }
                                { stepNumber === 1 && (<DietGenerator nextStep={ () => setStepNumber(stepNumber + 1) }
                                                                      generateDiet={ generateDiet }/>) }
                                { stepNumber === 2 && (<DietDisplayer/>) }
                            </Flex>
                        </Paper>
                    </Flex>
                </Container>
            </Container>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#00593b" fill-opacity="1"
                          d="M0,64L60,106.7C120,149,240,235,360,229.3C480,224,600,128,720,90.7C840,53,960,75,1080,74.7C1200,75,1320,53,1380,42.7L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
            </div>
            <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' } }>
                <Text style={ { color: 'white' } }>Ofrecido por</Text>
                <img src={ logo } alt="Logo" style={ { width: '200px' } }
                     onClick={ () => window.open('https://agrari.es') }/>
            </div>
        </>
    );

};

export default Login;
