import axios from 'axios';
export function generateRecipe(restrictions: RecipeRestriction[], userId: string, email: string, shouldSubscribe: boolean) {
    return axios({
        method: 'post',
        url: 'https://us-central1-agrari-recetas.cloudfunctions.net/generateRecipe',
        data: {
            restrictions,
            userId,
            email,
            shouldSubscribe
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export interface RecipeRestriction {
    name: string;
    value: string;
}
