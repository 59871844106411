import React from "react";
import { Button, MultiSelect, Title } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';
import { RecipeRestriction } from '../services/Firebase/firebase.service';

export interface IEmailCatcherProps {
    nextStep: () => void;
    generateDiet: (diet: RecipeRestriction[]) => void;
}

const dietOptions = [
    { label: 'Ninguna', value: 'none' },
    { label: 'Intolerancia a la lactosa', value: 'lactosa' },
    { label: 'Intolerancia al gluten', value: 'gluten' },
    { label: 'Alergia a Frutos secos', value: 'frutos_secos' },
    { label: 'Intolerancia a la fructosa', value: 'fructosa' },
    { label: 'Intolerancia al pescado', value: 'pescateriano' },
    { label: 'Sin azúcar', value: 'azucar' },
    { label: 'Intolerancia a los sulfitos', value: 'sulfitos' },
    { label: 'Intolerancia al huevo', value: 'huevo' },
    { label: 'Veganismo', value: 'vegano' },
    { label: 'Vegetarianismo', value: 'vegetariano' },
]

export const DietGenerator = ({ nextStep, generateDiet }: IEmailCatcherProps) => {
    const [form, setForm] = React.useState<string[]>(['none']);

    const submit = () => {
        let diet: RecipeRestriction[] = [];

        // Get the diet from the form and add the labels from dietOptions
        form.forEach((item) => {
            if(item !== 'none'){
                diet.push({name: dietOptions.find((option) => option.value === item)?.label ?? '', value: item});
            }
        });

        generateDiet(diet);
        nextStep();
    };

    return (
        <>
            <Title order={ 2 }>Restricciones:</Title>
            <MultiSelect
                data={ dietOptions }
                value={form} onChange={setForm}
                placeholder="Elige las restricciones"
                defaultValue={ ['none'] }
                clearButtonLabel="Limpiar seleccion"
                clearable
            />
            <Button rightIcon={ <IconChevronRight/> } onClick={ submit }>Ver recetas</Button>
        </>
    );

};
