import React, { useEffect } from 'react';
import './App.css';
import Home from './views/Home';
import {AppShell, MantineProvider} from '@mantine/core';
import { SessionService } from './services/Session/SessionService';

function App() {
    useEffect(() => {
        new SessionService().generateSession();
    }, []);

    return (
        <MantineProvider>
            <AppShell padding={0}>
                <Home/>
            </AppShell>
        </MantineProvider>
    );
}

export default App;
