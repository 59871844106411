import { LocalStorageService } from '../APIs/Storage/LocalStorageService';
import { nanoid } from 'nanoid';

export class SessionService {

    async generateSession(): Promise<string> {
        //Search LocalStorage for a session if not found generate a new one
        const storageService = new LocalStorageService();
        let id = await storageService.get<string>("AgrariId");
        try {
            if(id === null) {
                id = nanoid();
            }

            await storageService.store("AgrariId", id);
            return id;
        } catch (error) {
            await storageService.store("AgrariId", nanoid());
        }

        return id;
    }

    async getSession(): Promise<string> {
        const storageService = new LocalStorageService();
        let id;
        try {
            id = await storageService.get<string>("AgrariId");
        } catch (error) {
            throw error;
        }
        return id;
    }
}
