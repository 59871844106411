import { StorageService, StorageTypeValue } from './StorageService';

export class LocalStorageService implements StorageService {
    async store<T>(id: StorageTypeValue, data: T): Promise<void> {
        return localStorage.setItem(id, JSON.stringify(data));
    }

    async get<T>(id: StorageTypeValue): Promise<T> {
        const data = localStorage.getItem(id);
        return data && JSON.parse(data);
    }

    async remove(id: StorageTypeValue): Promise<void> {
        localStorage.removeItem(id);
    }

    async clear(): Promise<void> {
        localStorage.clear();
    }
}
